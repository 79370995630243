<template>
  <div class="tip">
    <div class="container">
      <div class="main">
        <p>
          请把右手食指放在键盘
          <span class="keyBtn">空格</span>
          键上
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      timer: null,
    };
  },
  methods: {},
  beforeDestroy() {
    clearTimeout(this.timer);
  },
  mounted() {
    this.timer = setTimeout(() => {
      this.$router.replace('/continuous');
    }, 2000);
  },
};
</script>
<style lang="scss">
@media screen and (max-width: 1900px) {
  .tip {
    display: table-cell;
    vertical-align: middle;
    .container {
      .main {
        text-align: center;
        padding: 280px 0;
        p {
          font-size: 32px;
          font-family: 'SYHeiBold';
          color: #5f9160;
          .keyBtn {
            width: 144px;
            height: 70px;
            display: inline-block;
            line-height: 70px;
            margin: 0 auto;
            text-align: center;
            background-image: url('../../assets/image/continuous/space.png');
            background-repeat: no-repeat;
            background-size: cover;
            font-size: 34px;
            color: #ffffff;
            font-family: 'SYHeiBold';
            cursor: url('http://upload.jahr.janjk.com/hand.ico'), pointer;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1900px) {
  .tip {
    display: table-cell;
    vertical-align: middle;
    .container {
      .main {
        text-align: center;
        padding: 364px 0;
        p {
          font-size: 42px;
          font-family: 'SYHeiBold';
          color: #5f9160;
          .keyBtn {
            width: 187px;
            height: 91px;
            display: inline-block;
            line-height: 91px;
            margin: 0 auto;
            text-align: center;
            background-image: url('../../assets/image/continuous/space.png');
            background-repeat: no-repeat;
            background-size: cover;
            font-size: 44px;
            color: #ffffff;
            font-family: 'SYHeiBold';
            cursor: url('http://upload.jahr.janjk.com/hand.ico'), pointer;
          }
        }
      }
    }
  }
}
</style>
